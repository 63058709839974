<template>
  <li
    class="mt-0 mb-4"
  >
    <p class="mt-0">Room {{ num }}</p>
    <v-row>
      <v-col cols="6" class="py-0">
        <v-select
          :items="adultsItems"
          v-model="adults"
          label="Adults"
          outlined
          dense
          color="white"
          item-color="red"
          @input="getRoomPrice"
        ></v-select>
      </v-col>

      <v-col cols="6" class="py-0">
        <v-select
          :items="childrenItems"
          v-model="children"
          label="Children"
          outlined
          dense
          color="white"
          item-color="red"
        ></v-select>
      </v-col>
    </v-row>

    <div v-if="children && adults >= 1">
      <p>Children's age at time of travel</p>

      <v-row>
        <v-col :cols="cols" class="py-0">
          <v-select
            v-if="children >= 1"
            :items="childrenAges"
            v-model.number="age1"
            label="Age"
            outlined
            dense
            color="white"
            item-color="red"
            @input="getRoomPrice"
          ></v-select>
        </v-col>

        <v-col :cols="cols" class="py-0">
          <v-select
            v-if="children >= 2"
            :items="childrenAges"
            v-model.number="age2"
            label="Age"
            outlined
            dense
            color="white"
            item-color="red"
            @input="getRoomPrice"
          ></v-select>
        </v-col>

        <v-col :cols="cols" class="py-0">
          <v-select
            v-if="children >= 3"
            :items="childrenAges"
            v-model.number="age3"
            label="Age"
            outlined
            dense
            color="white"
            item-color="red"
            @input="getRoomPrice"
          ></v-select>
        </v-col>

        <v-col :cols="cols" class="py-0">
          <v-select
            v-if="children === 4"
            :items="childrenAges"
            v-model.number="age4"
            label="Age"
            outlined
            dense
            color="white"
            item-color="red"
            @input="getRoomPrice"
          ></v-select>
        </v-col>
      </v-row>
    </div>
  </li>
</template>

<script>
export default {
  name: 'PriceCalculatorRoom',
  props: {
    num: Number,
    adultPrice: Number,
    supplement: Number,
    amendmentChild: Number,
    ageChild: Number,
    amendmentInfant: Number,
    ageInfant: Number,
    childrenAges: Array,
  },
  model: {
    prop: 'finalPrice',
    event: 'input',
  },

  data: () => ({
    adults: null,
    adultsItems: [1, 2, 3, 4],
    children: null,
    childrenItems: [0, 1, 2, 3, 4],
    age1: null,
    age2: null,
    age3: null,
    age4: null,
  }),

  computed: {
    cols() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.md) {
        if (this.children >= 2) {
          return 6;
        }

        return 12;
      }

      if (this.children === 2) {
        return 6;
      }

      if (this.children === 3) {
        return 4;
      }

      if (this.children === 4) {
        return 3;
      }

      return 12;
    },
    adultsPrice() {
      if (this.adults === 1 && this.children === null) {
        return this.adultPrice + this.supplement;
      }

      return this.adultPrice * this.adults;
    },
    childPrice1() {
      return this.getChildPrice(this.age1);
    },

    childPrice2() {
      return this.getChildPrice(this.age2);
    },

    childPrice3() {
      return this.getChildPrice(this.age3);
    },
    childPrice4() {
      return this.getChildPrice(this.age4);
    },
    finalPrice() {
      return (
        this.adultsPrice
        + this.childPrice1
        + this.childPrice2
        + this.childPrice3
        + this.childPrice4
      );
    },
  },

  methods: {
    getRoomPrice() {
      this.$emit('room-price', this.finalPrice);
    },
    getChildPrice(age) {
      if (age < this.ageInfant && age > 0) {
        return this.adultPrice * this.amendmentInfant;
      }

      if (age < this.ageChild && age >= this.ageInfant) {
        return this.adultPrice * this.amendmentChild;
      }

      return 0;
    },
  },
};
</script>

<style lang="scss"></style>
