<template>
  <v-form>
    <v-text-field
      label="Name"
      outlined
      dense
      type="text"
      color="white"
    ></v-text-field>

    <v-text-field
      label="Email"
      outlined
      dense
      type="email"
      color="white"
    ></v-text-field>

    <v-text-field
      label="Phone"
      outlined
      dense
      type="tel"
      color="white"
    ></v-text-field>

    <v-menu
      v-model="col1"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      min-width="auto"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date1"
          label="Date"
          append-icon="mdi-calendar"
          readonly
          color="white"
          outlined
          dense
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        range
        no-title
        color="red"
        locale="en-en"
      ></v-date-picker>
    </v-menu>

    <v-select
      :items="selectItems"
      label="Select a category"
      v-model="cat"
      outlined
      dense
      color="white"
      item-color="red"
    ></v-select>

    <v-select
      :items="rooms"
      label="Rooms"
      v-model="room"
      outlined
      dense
      color="white"
      item-color="red"
    ></v-select>

    <ul
      v-if="room >= 1"
      class="description-list"
    >
      <price-calculator-room
        :num="1"
        v-if="room >= 1"
        :adult-price="adultPrice"
        :supplement="supplement"
        :amendmentChild="amendmentChild"
        :ageChild="ageChild"
        :amendmentInfant="amendmentInfant"
        :ageInfant="ageInfant"
        :childrenAges="childrenAges"
        @room-price="getPriceRoom1"
      ></price-calculator-room>

      <price-calculator-room
        :num="2"
        v-if="room >= 2"
        :adult-price="adultPrice"
        :supplement="supplement"
        :amendmentChild="amendmentChild"
        :ageChild="ageChild"
        :amendmentInfant="amendmentInfant"
        :ageInfant="ageInfant"
        :childrenAges="childrenAges"
        @room-price="getPriceRoom2"
      ></price-calculator-room>

      <price-calculator-room
        :num="3"
        v-if="room >= 3"
        :adult-price="adultPrice"
        :supplement="supplement"
        :amendmentChild="amendmentChild"
        :ageChild="ageChild"
        :amendmentInfant="amendmentInfant"
        :ageInfant="ageInfant"
        :childrenAges="childrenAges"
        @room-price="getPriceRoom3"
      ></price-calculator-room>

      <price-calculator-room
        :num="4"
        v-if="room >= 4"
        :adult-price="adultPrice"
        :supplement="supplement"
        :amendmentChild="amendmentChild"
        :ageChild="ageChild"
        :amendmentInfant="amendmentInfant"
        :ageInfant="ageInfant"
        :childrenAges="childrenAges"
        @room-price="getPriceRoom4"
      ></price-calculator-room>

      <price-calculator-room
        :num="5"
        v-if="room >= 5"
        :adult-price="adultPrice"
        :supplement="supplement"
        :amendmentChild="amendmentChild"
        :ageChild="ageChild"
        :amendmentInfant="amendmentInfant"
        :ageInfant="ageInfant"
        :childrenAges="childrenAges"
        @room-price="getPriceRoom5"
      ></price-calculator-room>

      <price-calculator-room
        :num="6"
        v-if="room >= 6"
        :adult-price="adultPrice"
        :supplement="supplement"
        :amendmentChild="amendmentChild"
        :ageChild="ageChild"
        :amendmentInfant="amendmentInfant"
        :ageInfant="ageInfant"
        :childrenAges="childrenAges"
        @room-price="getPriceRoom6"
      ></price-calculator-room>

      <price-calculator-room
        :num="7"
        v-if="room >= 7"
        :adult-price="adultPrice"
        :supplement="supplement"
        :amendmentChild="amendmentChild"
        :ageChild="ageChild"
        :amendmentInfant="amendmentInfant"
        :ageInfant="ageInfant"
        :childrenAges="childrenAges"
        @room-price="getPriceRoom7"
      ></price-calculator-room>

      <price-calculator-room
        :num="8"
        v-if="room >= 8"
        :adult-price="adultPrice"
        :supplement="supplement"
        :amendmentChild="amendmentChild"
        :ageChild="ageChild"
        :amendmentInfant="amendmentInfant"
        :ageInfant="ageInfant"
        :childrenAges="childrenAges"
        @room-price="getPriceRoom8"
      ></price-calculator-room>

      <price-calculator-room
        :num="9"
        v-if="room >= 9"
        :adult-price="adultPrice"
        :supplement="supplement"
        :amendmentChild="amendmentChild"
        :ageChild="ageChild"
        :amendmentInfant="amendmentInfant"
        :ageInfant="ageInfant"
        :childrenAges="childrenAges"
        @room-price="getPriceRoom9"
      ></price-calculator-room>

      <price-calculator-room
        :num="10"
        v-if="room === 10"
        :adult-price="adultPrice"
        :supplement="supplement"
        :amendmentChild="amendmentChild"
        :ageChild="ageChild"
        :amendmentInfant="amendmentInfant"
        :ageInfant="ageInfant"
        :childrenAges="childrenAges"
        @room-price="getPriceRoom10"
      ></price-calculator-room>
    </ul>

    <v-textarea
      name="input-7-1"
      label="Your preferences"
      value=""
      outlined
      dense
      placeholder="Please advise your tour requirements"
      color="white"
    ></v-textarea>

    <p v-if="finalPrice">Total Price: {{ finalPrice }}</p>

    <v-btn
      right
      color="red"
      class="mr-4"
    >
      Submit
    </v-btn>
  </v-form>
</template>

<script>
import PriceCalculatorRoom from '@/components/PriceCalculatorRoom.vue';

export default {
  name: 'PriceCalculator',
  components: {
    PriceCalculatorRoom,
  },

  props: {
    price: Object,
    adultPrice: Number,
    supplement: Number,
    amendmentChild: Number,
    ageChild: Number,
    amendmentInfant: Number,
    ageInfant: Number,
    childrenAges: Array,
  },

  data: () => ({
    dates: [new Date().toISOString()
      .substr(0, 10), new Date().toISOString()],
    room: 1,
    rooms: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    selectItems: ['Tourist', 'Luxury', 'Luxury superior', 'Top Luxury'],
    roomPrice1: 0,
    roomPrice2: 0,
    roomPrice3: 0,
    roomPrice4: 0,
    roomPrice5: 0,
    roomPrice6: 0,
    roomPrice7: 0,
    roomPrice8: 0,
    roomPrice9: 0,
    roomPrice10: 0,
    cat: null,
    date1: new Date().toISOString()
      .substr(0, 10),
    date2: new Date().toISOString()
      .substr(0, 10),
    col1: false,
    col2: false,
  }),

  computed: {
    finalPrice() {
      return (
        this.roomPrice1
        + this.roomPrice2
        + this.roomPrice3
        + this.roomPrice4
        + this.roomPrice5
        + this.roomPrice6
        + this.roomPrice7
        + this.roomPrice8
        + this.roomPrice9
        + this.roomPrice10
      );
    },
  },

  methods: {
    getPriceRoom1(price) {
      this.roomPrice1 = price;
    },
    getPriceRoom2(price) {
      this.roomPrice2 = price;
    },
    getPriceRoom3(price) {
      this.roomPrice3 = price;
    },
    getPriceRoom4(price) {
      this.roomPrice4 = price;
    },
    getPriceRoom5(price) {
      this.roomPrice5 = price;
    },
    getPriceRoom6(price) {
      this.roomPrice6 = price;
    },
    getPriceRoom7(price) {
      this.roomPrice7 = price;
    },
    getPriceRoom8(price) {
      this.roomPrice8 = price;
    },
    getPriceRoom9(price) {
      this.roomPrice9 = price;
    },
    getPriceRoom10(price) {
      this.roomPrice10 = price;
    },
  },
};
</script>

<style lang="scss">
.description-list {
  margin: 0;
  padding: 0 !important;
  list-style: none;
}
</style>
