<template>
  <div>
    <v-carousel
      v-if="$vuetify.breakpoint.xsOnly"
      show-arrows-on-hover
      :cycle="true"
      :interval="9000"
      :height="260"
    >
      <v-carousel-item
        v-for="(img, name) in images"
        :key="name"
        :src="img"
      >
      </v-carousel-item>
    </v-carousel>

    <v-container>

      <v-row v-if="$vuetify.breakpoint.smAndUp">
        <v-col sm="6" lg="3">
          <v-row no-gutters>
            <v-col>
              <v-img height="150" :src="images.img01" class="mb-6"></v-img>
            </v-col>
            <v-col>
              <v-img height="150" :src="images.img02"></v-img>
            </v-col>
          </v-row>
        </v-col>

        <v-col sm="6" lg="6">
          <v-img height="324" :src="images.img03"></v-img>
        </v-col>

        <v-col sm="12" lg="3">
          <v-row no-gutters>
            <v-col sm="6" lg="12">
              <v-img
                height="150"
                :src="images.img04"
                :class="$vuetify.breakpoint.lgAndUp ? 'mb-6' : 'mr-3'"
              ></v-img>
            </v-col>
            <v-col sm="6" lg="12">
              <v-img
                height="150"
                :src="images.img05"
                :class="$vuetify.breakpoint.lgAndUp ? '' : 'ml-3'"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <h1 class="mt-8 mb-2 text-h3 font-weight-medium text-center">
        <div>{{ $vuetify.lang.t(title) }}</div>
        <div class="text-h6 mt-2">
          {{ days }} {{ $vuetify.lang.t('$vuetify.tour.days') }} /
          {{ nights }} {{ $vuetify.lang.t('$vuetify.tour.nights') }}.
          {{ $vuetify.lang.t('$vuetify.tour.regularTrips') }}
        </div>
      </h1>

      <v-row>
        <v-col cols="12" md="8">
          <v-sheet
            color=""
            elevation="8"
            class="pa-4"
          >
            <div v-html="$vuetify.lang.t(intro)"></div>

            <v-tabs
              v-model="tab"
              background-color="transparent"
              color="white"
              grow
              :show-arrows="$vuetify.breakpoint.xsOnly"
            >
              <v-tab
                v-for="item in items"
                :key="item"
              >
                {{ $vuetify.lang.t(item) }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="mt-4">
              <v-tab-item>
                <v-chip color="red" class="mb-2 mt-4">
                  <h3 class="text-h6">
                    {{ $vuetify.lang.t('$vuetify.tour.include') }}
                  </h3>
                </v-chip>
                <ul class="description-list">
                  <li
                    v-for="item in includes"
                    :key="item"
                    class="mb-1"
                  >
                    <v-icon
                      v-text="'mdi-check'"
                      class="mr-2"
                      color="green lighten-2"
                    ></v-icon>
                    {{ $vuetify.lang.t(item) }}
                  </li>
                </ul>

                <v-chip color="red" class="mb-2 mt-4">
                  <h3 class="text-h6">
                    {{ $vuetify.lang.t('$vuetify.tour.notInclude') }}
                  </h3>
                </v-chip>

                <ul class="description-list">
                  <li
                    v-for="item in notInclude"
                    :key="item"
                    class="mb-1"
                  >
                    <v-icon
                      v-text="'mdi-close'"
                      class="mr-2"
                      color="red lighten-2"
                    ></v-icon>
                    {{ $vuetify.lang.t(item) }}
                  </li>
                </ul>

                <v-chip color="red" class="mb-2 mt-4">
                  <h3 class="text-h6">
                    {{ $vuetify.lang.t('$vuetify.tour.trip') }}
                  </h3>
                </v-chip>

                <ul class="description-list">
                  <li
                    v-for="item in trip"
                    :key="item"
                    class="mb-1"
                  >
                    <v-icon
                      v-text="'mdi-chevron-right'"
                      class="mr-2"
                      color="white"
                    ></v-icon>
                    {{ $vuetify.lang.t(item) }}
                  </li>
                </ul>

                <v-chip color="red" class="mb-2 mt-4">
                  <h3 class="text-h6">
                    {{ $vuetify.lang.t('$vuetify.tour.notes') }}
                  </h3>
                </v-chip>
                <ul class="description-list">
                  <li
                    v-for="item in notes"
                    :key="item"
                    class="mb-1"
                  >
                    <v-icon
                      v-text="'mdi-chevron-right'"
                      class="mr-2"
                      color="white"
                    ></v-icon>
                    {{ $vuetify.lang.t(item) }}
                  </li>
                </ul>
              </v-tab-item>

              <v-tab-item>
                <div v-if="$vuetify.breakpoint.xsOnly">
                  <v-card
                    v-for="(item, i) in timeline"
                    :key="i"
                    color="red"
                    dark
                  >
                    <v-card-title class="pa-2 flex">
                      <h3
                        v-text="$vuetify.lang.t('$vuetify.tour.day') + ' ' + (i + 1)"
                        class="title text-center mx-auto"
                      ></h3>
                    </v-card-title>
                    <v-card-text class="grey darken-4 text--primary pa-2">
                      <p
                        v-if="item.title"
                        class="headline font-weight-light mb-4"
                      >
                        {{ $vuetify.lang.t(item.title) }}
                      </p>
                      <div v-html="$vuetify.lang.t(item.text)"></div>
                    </v-card-text>
                  </v-card>
                </div>

                <v-timeline v-if="$vuetify.breakpoint.smAndUp">
                  <v-timeline-item
                    v-for="(item, i) in timeline"
                    :key="i"
                    color="red"
                    small
                  >
                    <template v-slot:opposite>
                      <span
                        class="headline font-weight-bold"
                        v-text="$vuetify.lang.t('$vuetify.tour.day') + ' ' + (i + 1)"
                      ></span>
                    </template>
                    <div class="py-4">
                      <h3
                        v-if="item.title"
                        class="headline font-weight-light mb-4"
                      >
                        {{ $vuetify.lang.t(item.title) }}
                      </h3>
                      <div v-html="$vuetify.lang.t(item.text)"></div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </v-tab-item>

              <v-tab-item>
                <p class="mb-2 mt-4">
                  {{ $vuetify.lang.t(priceIntro) }}
                </p>

                <v-chip color="red" class="mb-2 mt-4">
                  <h3 class="text-h6">
                    {{ $vuetify.lang.t('$vuetify.tour.childPolicy.title') }}
                  </h3>
                </v-chip>
                <ul class="description-list mb-4">
                  <li
                    v-for="item in childPolicy"
                    :key="item"
                    class="mb-1"
                  >
                    <v-icon
                      v-text="'mdi-chevron-right'"
                      class="mr-2"
                      color="white"
                    ></v-icon>
                    {{ $vuetify.lang.t(item) }}
                  </li>
                </ul>

                <v-tabs
                  v-model="category"
                  background-color="transparent"
                  color="white"
                  grow
                  :show-arrows="$vuetify.breakpoint.xsOnly"
                >
                  <v-tab
                    v-for="item in categories"
                    :key="item"
                  >
                    {{ $vuetify.lang.t(item) }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="category" class="mt-4">
                  <v-tab-item>
                    <v-row>
                      <v-col
                        v-for="(item) in tourist"
                        :key="item.name"
                        cols="12"
                        :lg="tourist.length % 2 === 0 ? 6 : 4"
                      >
                        <v-card
                          color="red"
                          dark
                        >
                          <v-card-title class="pa-2 flex">
                            <h3
                              v-html="$vuetify.lang.t(item.name)"
                              class="title text-center mx-auto"
                            ></h3>
                          </v-card-title>
                          <v-card-text class="grey darken-4 text--primary pa-2">
                            <ul class="description-list">
                              <li
                                v-for="hotel in item.hotels"
                                :key="hotel"
                                class="text-center mb-1"
                              >
                                {{ hotel }}
                              </li>
                            </ul>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          <v-chip color="red">
                            {{price.tourist.priceDouble}}€
                          </v-chip>
                          {{ $vuetify.lang.t('$vuetify.tour.priceDouble') }}
                        </p>
                        <p>
                          <v-chip color="red">
                            {{price.tourist.supplement}}€
                          </v-chip>
                          {{ $vuetify.lang.t('$vuetify.tour.single') }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item>
                    <v-row>
                      <v-col
                        v-for="(item) in luxury"
                        :key="item.name"
                        cols="12"
                        :lg="luxury.length % 2 === 0 ? 6: 4"
                      >
                        <v-card
                          color="red"
                          dark
                        >
                          <v-card-title class="pa-2 flex">
                            <h3
                              v-html="$vuetify.lang.t(item.name)"
                              class="title text-center mx-auto"
                            ></h3>
                          </v-card-title>
                          <v-card-text class="grey darken-4 text--primary pa-2">
                            <ul class="description-list">
                              <li
                                v-for="hotel in item.hotels"
                                :key="hotel"
                                class="text-center mb-1"
                              >
                                {{ hotel }}
                              </li>
                            </ul>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          <v-chip color="red">
                            {{price.luxury.priceDouble}}€
                          </v-chip>
                          {{ $vuetify.lang.t('$vuetify.tour.priceDouble') }}
                        </p>
                        <p>
                          <v-chip color="red">
                            {{price.luxury.supplement}}€
                          </v-chip>
                          {{ $vuetify.lang.t('$vuetify.tour.single') }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item>
                    <v-row>
                      <v-col
                        v-for="(item) in superior"
                        :key="item.name"
                        cols="12"
                        :lg="superior.length % 2 === 0 ? 6: 4"
                      >
                        <v-card
                          color="red"
                          dark
                        >
                          <v-card-title class="pa-2 flex">
                            <h3
                              v-html="$vuetify.lang.t(item.name)"
                              class="title text-center mx-auto"
                            ></h3>
                          </v-card-title>
                          <v-card-text class="grey darken-4 text--primary pa-2">
                            <ul class="description-list">
                              <li
                                v-for="hotel in item.hotels"
                                :key="hotel"
                                class="text-center mb-1"
                              >
                                {{ hotel }}
                              </li>
                            </ul>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          <v-chip color="red">
                            {{price.superior.priceDouble}}€
                          </v-chip>
                          {{ $vuetify.lang.t('$vuetify.tour.priceDouble') }}
                        </p>
                        <p>
                          <v-chip color="red">
                            {{price.superior.supplement}}€
                          </v-chip>
                          {{ $vuetify.lang.t('$vuetify.tour.single') }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item>
                    <v-row>
                      <v-col
                        v-for="(item) in top"
                        :key="item.name"
                        cols="12"
                        :lg="top.length % 2 === 0 ? 6: 4"
                      >
                        <v-card
                          color="red"
                          dark
                        >
                          <v-card-title class="pa-2 flex">
                            <h3
                              v-html="$vuetify.lang.t(item.name)"
                              class="title text-center mx-auto"
                            ></h3>
                          </v-card-title>
                          <v-card-text class="grey darken-4 text--primary pa-2">
                            <ul class="description-list">
                              <li
                                v-for="hotel in item.hotels"
                                :key="hotel"
                                class="text-center mb-1"
                              >
                                {{ hotel }}
                              </li>
                            </ul>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12">
                        <p>
                          <v-chip color="red">
                            {{price.top.priceDouble}}€
                          </v-chip>
                          {{ $vuetify.lang.t('$vuetify.tour.priceDouble') }}
                        </p>
                        <p>
                          <v-chip color="red">
                            {{price.top.supplement}}€
                          </v-chip>
                          {{ $vuetify.lang.t('$vuetify.tour.single') }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                </v-tabs-items>
              </v-tab-item>
            </v-tabs-items>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="4">
          <v-sheet
            color=""
            elevation="8"
            class="pa-4"
          >
            <price-calculator
              :price="price"
              :adult-price="1000"
              :supplement="300"
              :amendmentChild="0.5"
              :ageChild="12"
              :amendmentInfant="0.25"
              :ageInfant="2"
              :childrenAges="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
            ></price-calculator>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>
import PriceCalculator from '@/components/PriceCalculator.vue';
import { homeCards } from '../../scripts/images';

export default {
  name: 'EgyptAndMarsaAlam',
  components: {
    PriceCalculator,
  },
  data: () => ({
    adults: 2,
    children: 0,
    infants: 0,
    room: 1,
    selectItems: ['Tourist', 'Luxury', 'Luxury superior', 'Top Luxury'],
    adultsItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    childrenItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    infantsItems: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    rooms: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    cat: null,
    date1: new Date().toISOString().substr(0, 10),
    date2: new Date().toISOString().substr(0, 10),
    col1: false,
    col2: false,
    tab: null,
    category: null,
    items: [
      '$vuetify.tour.description',
      '$vuetify.tour.itinerary',
      '$vuetify.tour.price',
    ],
    images: {
      img01: homeCards.egypt,
      img02: homeCards.jordan,
      img03: homeCards.emiratesArabs,
      img04: homeCards.india,
      img05: homeCards.israel,
    },
    days: 11,
    nights: 10,
    title: '$vuetify.tours.egypt.egyptAndMarsaAlam.title',
    intro: '$vuetify.tours.egypt.egyptAndMarsaAlam.intro',
    includes: [
      '$vuetify.tours.egypt.egyptAndMarsaAlam.include.li1',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.include.li2',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.include.li3',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.include.li4',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.include.li5',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.include.li6',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.include.li7',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.include.li8',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.include.li9',
    ],
    notInclude: [
      '$vuetify.tours.egypt.egyptAndMarsaAlam.notInclude.li1',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.notInclude.li2',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.notInclude.li3',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.notInclude.li4',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.notInclude.li5',
    ],
    trip: [
      '$vuetify.tours.egypt.egyptAndMarsaAlam.trip.li1',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.trip.li2',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.trip.li3',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.trip.li4',
    ],
    notes: [
      '$vuetify.tours.egypt.egyptAndMarsaAlam.notes.li1',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.notes.li2',
      '$vuetify.tours.egypt.egyptAndMarsaAlam.notes.li3',
    ],
    timeline: [
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d1.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d1.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d2.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d2.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d3.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d3.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d4.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d4.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d5.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d5.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d6.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d6.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d7.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d7.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d8.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d8.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d9.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d9.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d10.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d10.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d11.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d11.text',
      },
      {
        title: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d12.title',
        text: '$vuetify.tours.egypt.egyptAndMarsaAlam.time.d12.text',
      },
    ],
    priceIntro: '$vuetify.tours.egypt.egyptAndMarsaAlam.prices.intro',
    childPolicy: [
      '$vuetify.tour.childPolicy.li1',
      '$vuetify.tour.childPolicy.li2',
      '$vuetify.tour.childPolicy.li3',
    ],
    categories: [
      '$vuetify.tour.tourist',
      '$vuetify.tour.luxury',
      '$vuetify.tour.superior',
      '$vuetify.tour.top',
    ],
    tourist: [
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.marsaAlam',
        hotels: [
          'The three corners happy life beach resort.',
          'Blue reef.',
          'Club calimera habiba beach.',
          'Tui suneo reef marsa.',
          'Moon resort marsa alam.',
          'o similar',
        ],
      },
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.nile',
        hotels: [
          'M/S Voyageur',
          'M/S Mahrusa',
          'M/S Sara 1 y 2',
          'M/S Champlion',
          'M/S Radamis',
          'M/S Viking',
          'o similar',
        ],
      },
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.cairo',
        hotels: [
          'Mercure',
          'Barcelo',
          'Cairo pyramid',
          'Swiss inn',
          'Intercontinental pyramids park',
          'o similar',
        ],
      },
    ],
    luxury: [
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.marsaAlam',
        hotels: [
          'Royal tulip beach resort',
          'Malikia resort abu dabbab',
          'o similar',

        ],
      },
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.nile',
        hotels: [
          'M/S Concerto',
          'M/S Nile style',
          'M/S Blue shadow 2',
          'M/S Crown prince',
          'M/S Crown empress',
        ],
      },
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.cairo',
        hotels: [
          'Le meridian',
          'Steignburger pyramids',
          'Ramses hilton',
          'Movempic 6 octubre',
        ],
      },
    ],
    superior: [
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.marsaAlam',
        hotels: [
          'Jaz grand marsa',
          'Crowne plaza sahara',
          'Oasis port ghalib',
          'Kahramana beach resort',
        ],
      },
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.nile',
        hotels: [
          'M/S Le fayan',
          'M/S El farah',
          'M/S Tulip',
          'M/S Esmerlada',
          'M/S Alhambra',
          'M/S Nile premieum',
        ],
      },
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.cairo',
        hotels: [
          'Sheraton cairo',
          'Conrado',
          'Fairmount nile city',
          'Grand nile tower',
          'Steignburger el tahrir',
        ],
      },
    ],
    top: [
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.marsaAlam',
        hotels: [
          'Steigenberger coraya beach (adults only)',
          'Vime gorgonia beach',
          'Jaz samaya resort',
        ],
      },
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.nile',
        hotels: [
          'M/S My fair',
          'M/S Sonesta san george',
          'M/S Sonesta nile goddess',
        ],
      },
      {
        name: '$vuetify.tours.egypt.egyptAndMarsaAlam.accommodation.cairo',
        hotels: [
          '4 Season plaza',
          '4 Season el giza',
          'Sheraton al gazira',
          'Nile ritz',
        ],
      },
    ],
    price: {
      tourist: {
        priceDouble: 1397,
        supplement: 385,
      },
      luxury: {
        priceDouble: 1585,
        supplement: 450,
      },
      superior: {
        priceDouble: 1870,
        supplement: 804,
      },
      top: {
        priceDouble: 1980,
        supplement: 1027,
      },
    },
    priceDollar: {
      tourist: {
        priceDouble: 1769,
        supplement: 471,
      },
      luxury: {
        priceDouble: 1942,
        supplement: 551,
      },
      superior: {
        priceDouble: 2292,
        supplement: 985,
      },
      top: {
        priceDouble: 2427,
        supplement: 1258,
      },
    },
  }),
};
</script>

<style lang="scss">
.description-list {
  margin: 0;
  padding: 0 !important;
  list-style: none;
}
</style>
